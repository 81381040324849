<template>
    <div class="back-title">
        <div class="back-img" @click="backCourseSchedule">
            <img :src="backImg" alt="back" />
            <span>返回</span>
        </div>
        <div class="title">{{ title }}</div>
        <div></div>
    </div>
</template>
<script>
    export default {
        name: "BackTitle",
        props: {
            title: String
        },
        computed: {
            backImg () {
                return require('@/assets/images/TeachingResearchCourseScheduleTemp/back.png')
            }
        },
        methods: {
            backCourseSchedule () {
                this.$eventDispatch('backCourseSchedule')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .back-title {
        width: 100%;
        height: 47px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EDF0F2;
        .back-img {
            cursor: pointer;
            img {
                width: 10px;
                height: 9px;
            }
            span {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #363A40;
                margin: 0 8px;
            }
        }
        .back-img:hover img{
            transform: translateY(-60px);
            filter: drop-shadow(#3C7FFF 0 60px);
        }
        .back-img:hover span{
            color: #3C7FFF;
        }
        .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #363A40;
        }
    }
</style>
